import {faArrowUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {Component} from "react";
import styled from "styled-components";
import {Continent} from "./Continent";
import {Planet} from "./Planet";
import {Rocket} from "./Rocket";
import {Stars} from "./Stars";

/**
 * The height of the atmosphere.
 * @type {number}
 */
const atmosphereHeight = 0.4;

/**
 * The outer wrapper.
 */
const Wrapper = styled.article`
	align-items: center;
	background: linear-gradient(to top, #81b3e2, #1d92dc calc(${atmosphereHeight} * 0.1 * 100%), #0d3c7d calc(${atmosphereHeight} * 0.3 * 100%), #0c1f50 calc(${atmosphereHeight} * 0.4 * 100%), #060612 calc(${atmosphereHeight} * 0.6 * 100%), #020810 calc(${atmosphereHeight} * 0.7 * 100%), #010101 calc(${atmosphereHeight} * 0.8 * 100%), black calc(${atmosphereHeight} * 100%));
	color: white;
	display: flex;
	flex-direction: column;
	font-family: "Open Sans", sans-serif;
	justify-content: space-evenly;
	overflow: hidden;
	position: relative;
	text-shadow: 2px 2px 5px #333;
	width: 100%;

	a {
		color: cyan;
	}
`;

/**
 * The title bar.
 */
const TitleBar = styled.div`
	bottom: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	left: 0;
	margin-bottom: 1em;
	position: absolute;
	right: 0;
	z-index: 5;
`;

/**
 * The title.
 */
const Title = styled.h1`
	background: rgba(0, 0, 0, 0.1);
	border-radius: 1em;
	display: flex;
	flex-direction: row;
	gap: 1em;
	justify-content: center;
	padding: 0.3em 0.6em;
`;

/**
 * The content wrapper.
 */
const Content = styled.section`
`;

/**
 * The diameter of the base Planets.
 * @type {string}
 */
const baseDiameter = "400vw";

/**
 * A base Planet.
 */
const BasePlanet = styled(Planet)`
	height: ${baseDiameter};
	left: calc(-1 * ((${baseDiameter} - 100vw) / 2));
	position: absolute;
	right: calc(-1 * ((${baseDiameter} - 100vw) / 2));
	width: ${baseDiameter};
`;

/**
 * The target Planet.
 */
const TargetPlanet = styled(BasePlanet)`
	margin-top: calc(-1 * (${baseDiameter} - 20vh));
	top: 0;
`;

/**
 * The home Planet.
 */
const HomePlanet = styled(BasePlanet)`
	bottom: 0;
	margin-bottom: calc(-1 * (${baseDiameter} - 20vh));
`;

/**
 * A slideshow, but in space.
 * With awesome graphics.
 */
export class Spaceshow extends Component {
	static defaultProps = {
		rocketImage: null,
		rocketTargets: [],
		title: "Spaceshow"
	};

	/**
	 * Creates a new Spaceshow.
	 * @param properties The properties.
	 */
	constructor(properties) {
		super(properties);

		this.state = {
			rocket: React.createRef()
		};
	}

	/**
	 * Gets the Rocket component.
	 * @return {Rocket} The Rocket.
	 */
	get rocket() {
		return this.state.rocket.current;
	}

	render() {
		return (
			<Wrapper>
				<Stars stars="320" direction="left" color="#aaa" starWidth="1px" starHeight="1px" loopDuration="1280s">
					<Stars stars="160" direction="left" color="#aaa" starWidth="1px" starHeight="1px" loopDuration="640s">
						<Stars stars="80" direction="left" color="#aaa" starWidth="2px" starHeight="2px" loopDuration="320s">
							<Stars stars="40" direction="left" color="#aaa" starWidth="2px" starHeight="2px" loopDuration="160s">
								<Stars stars="20" direction="left" color="#aaa" starWidth="3px" starHeight="3px" loopDuration="80s">
									<Stars stars="10" direction="left" color="random" starWidth="3px" starHeight="3px" loopDuration="40s">
										<Stars stars="5" direction="left" color="random" starWidth="2px" starHeight="2px" loopDuration="20s">
											<TargetPlanet
												diameter="100%"
												background="#bebab4"
												atmosphere="#444c4a"
												atmosphereHeight="5em"
											>
												<Continent
													background="#807b75"
													border="#444"
													diameter="8%"
													key="WestCrater"
													latitude="99%"
													longitude="39%"
													borderRadius="0.3%"
												/>
												<Continent
													background="#807b75"
													border="#444"
													diameter="4%"
													key="SouthCrater"
													latitude="99%"
													longitude="52%"
													borderRadius="0.3%"
												/>
												<Continent
													background="#807b75"
													border="#444"
													diameter="1%"
													key="EastCrater"
													latitude="98%"
													longitude="59%"
													borderRadius="0.1%"
												/>
											</TargetPlanet>
											<Content>
												{this.props.children}
											</Content>
											<HomePlanet
												diameter="100%"
												background="#04397c"
												atmosphere="#7ccbf5"
												atmosphereHeight="10em"
											>
												<Continent
													background="#4c6535"
													border="#5e4e3d"
													diameter="14%"
													key="Europe"
													latitude="-1%"
													longitude="50%"
													borderRadius="0.5%"
												/>
												<Continent
													background="#5e4e3d"
													border="#444"
													diameter="0.8%"
													key="Ireland"
													latitude="1.2%"
													longitude="39%"
													borderRadius="0.1%"
												/>
												<Continent
													background="#5e4e3d"
													border="#444"
													diameter="1%"
													key="England"
													latitude="2%"
													longitude="40%"
													borderRadius="0.1%"
												/>
												<Continent
													background="#5e4e3d"
													border="#444"
													diameter="0.8%"
													key="Mallorca"
													latitude="2.4%"
													longitude="61%"
													borderRadius="0.1%"
												/>
											</HomePlanet>
											<Rocket ref={this.state.rocket} image={this.props.rocketImage} targets={this.props.rocketTargets}/>
											<TitleBar>
												<Title>
													<FontAwesomeIcon icon={faArrowUp}/>
													{this.props.title}
													<FontAwesomeIcon icon={faArrowUp}/>
												</Title>
											</TitleBar>
										</Stars>
									</Stars>
								</Stars>
							</Stars>
						</Stars>
					</Stars>
				</Stars>
			</Wrapper>
		);
	}
}
