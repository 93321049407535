/**
 * Something that can be run.
 */
export class Runnable {
	/**
	 * Creates a new Runnable.
	 */
	constructor() {
		/**
		 * Whether the Runnable is still running.
		 * @type {boolean}
		 * @private
		 */
		this._running = false;
	}

	/**
	 * Gets whether the Runnable is still running.
	 * @return {boolean} Whether the Runnable is still running.
	 */
	get running() {
		return this._running;
	}

	/**
	 * Runs the Runnable.
	 */
	run() {
		this._running = true;

		this._onRun();

		this._running = false;
	}

	/**
	 * Called whenever the Runnable is run.
	 */
	_onRun() {
	}
}