import {Cube} from "./Shapes/Cube";

/**
 * A skybox.
 */
export class Skybox extends Cube {
	/**
	 * Creates a new Skybox.
	 * @param {Camera} camera The camera to attach to.
	 */
	constructor(camera = null) {
		super();

		/**
		 * The camera to attach to.
		 * @type {Camera}
		 */
		this.camera = camera;

		// Resize the Cube
		this.width = 1e6;
		this.height = 1e6;
		this.depth = 1e6;
	}

	_onUpdate(delta) {
		// Move with the camera
		if (this.camera !== null) {
			this.coordinates = this.camera.coordinates;
		}
	}
}
