import {darken, invert, lighten, readableColor} from "polished";
import random from "random";
import randomColor from "randomcolor";
import React, {Component} from "react";
import styled from "styled-components";
import {Continent} from "./Continent";

const Border = styled.div`
	border-radius: 100%;
	height: ${props => props.diameter};
	position: relative;
	width: ${props => props.diameter};
`;

const Wrapper = styled.div`
	align-items: center;
	background: radial-gradient(circle, ${props => lighten(0.1, props.background)} 0%, ${props => props.background} 66%, ${props => darken(0.3, props.background)} 100%);
	border-radius: 100%;
	box-shadow: 0 0 ${props => props.atmosphereHeight} ${props => props.atmosphere};
	display: flex;
	flex-direction: column;
	font-size: 1.2em;
	height: 100%;
	justify-content: center;
	overflow: hidden;
	position: relative;
	text-align: center;
	width: 100%;

	h1, h2, h3, h4, h5, h6 {
		color: ${props => readableColor(props.background, lighten(0.2, invert(props.background)), darken(0.2, invert(props.background)), false)};
		margin-bottom: 0.6em;
	}

	> * {
		margin: 0 auto;
		max-width: 95%;
	}
`;

/**
 * A planet in the universe.
 */
export class Planet extends Component {
	static defaultProps = {
		atmosphere: null,
		atmosphereHeight: "0.25em",
		background: null,
		diameter: "1em"
	};

	static CreateRandom(continents = null) {
		if (continents === null) {
			continents = random.int(0, 7);
		}

		const createdContinents = [];
		for (let continent = 0; continent < continents; ++continent) {
			createdContinents.push(React.cloneElement(Continent.CreateRandom(), {key: continent}));
		}

		return (
			<Planet>
				{createdContinents}
			</Planet>
		);
	}

	render() {
		return (
			<Border
				className={this.props.className}
				diameter={this.props.diameter}
			>
				<Wrapper
					diameter={this.props.diameter}
					background={this.props.background === null ? randomColor({
						luminosity: "bright",
						format: "hex"
					}) : this.props.background}
					atmosphere={this.props.atmosphere === null ? randomColor({
						luminosity: "bright",
						format: "hex"
					}) : this.props.atmosphere}
					atmosphereHeight={this.props.atmosphereHeight}
				>
					{this.props.children}
				</Wrapper>
			</Border>
		);
	}
}
