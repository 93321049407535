import random from "random";
import randomColor from "randomcolor";
import React, {Component} from "react";
import styled from "styled-components";

/**
 * The outer wrapper.
 */
const Wrapper = styled.div`
	overflow: hidden;
`;

/**
 * The inner contents.
 */
const Contents = styled.div`
`;

/**
 * An animated starry background.
 */
export class Stars extends Component {
	static defaultProps = {
		color: "white",
		direction: "down",
		loopDuration: "50s",
		starBlur: "0",
		starHeight: "1px",
		starWidth: "1px",
		stars: 100
	};

	/**
	 * Creates a new Stars.
	 * @param properties
	 */
	constructor(properties) {
		super(properties);

		// Generate the stars
		const stars = [];
		for (let star = 0; star < this.props.stars; ++star) {
			const x = random.int(0, 100);
			const y = random.int(0, 100);
			const color = this.props.color === "random" ? randomColor({
				luminosity: "bright",
				format: "rgb"
			}) : this.props.color;
			stars.push(`${x}vw ${y}vh ${this.props.starBlur} ${color}`);

			// Add the star again but further on the template so it can loop
			stars.push(`${x +
			(
				this.props.direction === "left" || this.props.direction === "right" ? 100 : 0
			)}vw ${y +
			(
				this.props.direction === "up" || this.props.direction === "down" ? 100 : 0
			)}vh ${this.props.starBlur} ${color}`);
		}

		let fromTransforms = "translate";
		let toTransforms = "translate";
		if (this.props.direction === "up" || this.props.direction === "down") {
			fromTransforms += "Y(";
			toTransforms += "Y(";
			if (this.props.direction === "up") {
				fromTransforms += "0";
				toTransforms += "-100";
			} else {
				fromTransforms += "-100";
				toTransforms += "0";
			}
			fromTransforms += "vh)";
			toTransforms += "vh)";
		} else {
			fromTransforms += "X(";
			toTransforms += "X(";
			if (this.props.direction === "left") {
				fromTransforms += "0";
				toTransforms += "-100";
			} else {
				fromTransforms += "-100";
				toTransforms += "0";
			}
			fromTransforms += "vw)";
			toTransforms += "vw)";
		}

		this.state = {
			starLayer: styled.div`
				animation: starAnimation ${this.props.loopDuration} linear infinite;
				background: transparent;
				border-radius: 100%;
				bottom: 0;
				box-shadow: ${stars.join(", ")};
				height: ${this.props.starHeight};
				left: 0;
				position: fixed;
				right: 0;
				top: 0;
				width: ${this.props.starWidth};

				@keyframes starAnimation {
					from {
						transform: ${fromTransforms};
					}
					to {
						transform: ${toTransforms};
					}
				}
			`
		};
	}

	render() {
		return (
			<Wrapper>
				<this.state.starLayer/>
				<Contents>
					{this.props.children}
				</Contents>
			</Wrapper>
		);
	}
}
