import * as THREE from "three";
import {Shape} from "./Shape";

/**
 * A sphere.
 */
export class Sphere extends Shape {
	/**
	 * Gets the amount of height segments.
	 * @return {number} The amount of width segments.
	 */
	get heightSegments() {
		return this._component.geometry.heightSegments;
	}

	/**
	 * Sets the amount of height segments.
	 * @param {number} heightSegments The amount of height segments.
	 */
	set heightSegments(heightSegments) {
		this._component.geometry.heightSegments = heightSegments;
	}

	/**
	 * Gets the radius.
	 * @return {number} The radius.
	 */
	get radius() {
		return this._component.scale.x;
	}

	/**
	 * Sets the radius.
	 * @param {number} radius The radius.
	 */
	set radius(radius) {
		this._component.scale.set(radius, radius, radius);
	}

	/**
	 * Gets the amount of width segments.
	 * @return {number} The amount of width segments.
	 */
	get widthSegments() {
		return this._component.geometry.widthSegments;
	}

	/**
	 * Sets the amount of width segments.
	 * @param {number} widthSegments The amount of width segments.
	 */
	set widthSegments(widthSegments) {
		this._component.geometry.widthSegments = widthSegments;
	}

	_generateComponent() {
		return new THREE.Mesh(new THREE.SphereGeometry(1, 10, 10), new THREE.MeshStandardMaterial({color: 0x00ff00}));
	}
}
