import {Loopable} from "../Loopable";

/**
 * Represents a set of mappings from inputs to actions.
 */
export class Controls extends Loopable {
	/**
	 * Creates a new Controls.
	 * @param {Camera} camera The Camera to control.
	 * @param {Runner} runner The Runner to use.
	 */
	constructor(camera, runner) {
		super();

		/**
		 * The Camera to control.
		 * @type {Camera}
		 */
		this.camera = camera;

		/**
		 * The Runner to use.
		 * @type {Runner}
		 */
		this.runner = runner;

		/**
		 * The Three.js controls to use.
		 * @type {*}
		 * @protected
		 */
		this._controls = this._generateControls();
	}

	/**
	 * Generates the Three.js controls to use.
	 * @return {*} The controls.
	 */
	_generateControls() {
		return null;
	}
}