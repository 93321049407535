import {Controller} from "./Controls/Controller";
import {FlightControls} from "./Controls/FlightControls";
import {Renderer} from "./Graphics/Renderer";
import {Loopable} from "./Loopable";

/**
 * Runs the main simulation loops.
 */
export class Runner extends Loopable {
	/**
	 * Creates a new Runner.
	 * @param {Universe} universe The Universe to use.
	 * @param {Camera} camera The Camera to use.
	 * @param {Element} element The Element to render to.
	 */
	constructor(universe, camera, element) {
		super();

		/**
		 * The Universe to use.
		 * @type {Universe}
		 */
		this.universe = universe;

		/**
		 * The Camera to use.
		 * @type {Camera}
		 */
		this.camera = camera;

		/**
		 * The Element to render to.
		 * @type {Element}
		 */
		this.element = element;

		// Set up the Renderer
		this.renderer = new Renderer(this.universe, this.camera, this.element);

		// Set up the Controller
		this.controller = new Controller(new FlightControls(camera, this));
	}

	_onLoop(delta) {
		// Update the Universe
		this.universe.loop(delta);

		// Render the Camera
		this.renderer.loop(delta);

		// Apply the Controls
		this.controller.loop(delta);
	}
}