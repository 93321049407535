import React, {Component} from "react";
import styled from "styled-components";
import {Runner} from "./Runner";

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	box-sizing: border-box;
`;

/**
 * Allows the user to interact with the simulation Universe.
 */
export class Interface extends Component {
	/**
	 * Creates a new Interface.
	 * @param properties The props of the element.
	 */
	constructor(properties) {
		super(properties);

		/**
		 * Reference to the wrapper.
		 * @type {*}
		 * @private
		 */
		this._wrapperReference = React.createRef();
	}

	componentDidMount() {
		/**
		 * The runner to run the simulation logic.
		 * @type {Runner}
		 * @private
		 */
		this.runner = new Runner(this.props.universe, this.props.camera, this._wrapperReference.current);

		// Start the simulation loop
		this.runner.run();
	}

	componentWillUnmount() {
		// Stop the runner
		this.runner.looping = false;
	}

	render() {
		return (
			<Wrapper ref={this._wrapperReference}/>
		);
	}
}
