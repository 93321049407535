import "@fontsource/vt323";
import React, {Component} from "react";
import styled from "styled-components";
import {Target} from "./Target";

/**
 * The outer wrapper.
 */
const Wrapper = styled.div.attrs(properties => (
	{
		// As these properties change every frame, we should style the using attrs for performance
		style: {
			bottom: `calc(${properties.y} - (${properties.width} * 3) / 2)`,
			left: `calc(${properties.x} - ${properties.width} / 2)`,
			transform: `rotate(${properties.rotation})`
		}
	}
))`
	font-size: ${props => props.width};
	height: calc(${props => props.width} * 3);
	position: absolute;
	width: ${props => props.width};
	z-index: 75; // Positions the rocket in the middle of any planet rings
`;

/**
 * The tip.
 */
const Tip = styled.div`
	background: linear-gradient(45deg, darkred, red, darkred);
	width: 1em;
	height: 1em;
	left: 0;
	position: absolute;
	top: 0;
	transform: scaleX(0.5) rotate(45deg);
	z-index: 1;
`;

/**
 * The body.
 */
const Body = styled.div`
	background: linear-gradient(90deg, gray, darkgray, gray);
	border-radius: 100%;
	height: 2em;
	left: 0;
	position: absolute;
	top: 0.2em;
	width: 1em;
	z-index: 2;
`;

/**
 * The window.
 */
const Window = styled.div`
	background: url(${props => props.image});
	background-size: cover;
	border-radius: 100%;
	border: 0.05em solid darkred;
	height: 0.7em;
	left: 0.10em;
	position: absolute;
	top: 0.8em;
	width: 0.7em;
	z-index: 3;
`;

/**
 * The legs.
 */
const Leg = styled.div`
	background: linear-gradient(45deg, darkred, red, darkred);
	height: 0.4em;
	position: absolute;
	transform: scaleY(2) scaleX(0.5) rotate(45deg);
	width: 0.4em;
`;

/**
 * The left leg.
 */
const LeftLeg = styled(Leg)`
	top: 2em;
	left: 0;
	z-index: 3;
`;

/**
 * The right leg.
 */
const RightLeg = styled(Leg)`
	top: 2em;
	right: 0;
	z-index: 3;
`;

/*
 * The back leg.
 */
const BackLeg = styled(Leg)`
	top: 1.9em;
	left: 0.3em;
	z-index: 1;
`;

/**
 * A message shown by the rocket.
 */
const Message = styled.div.attrs(properties => (
	{
		// As these properties change every frame, we should style the using attrs for performance
		style: {
			transform: `rotate(${properties.rotation})`
		}
	}
))`
	background: white;
	border-radius: 0.5em;
	border: 1px solid gray;
	box-sizing: border-box;
	color: black;
	font-family: VT323, sans-serif;
	font-size: 0.25em;
	left: -1em;
	padding: 0.2em;
	position: absolute;
	right: -1em;
	text-align: center;
	text-shadow: none;
	top: 14em;
`;

/**
 * The connector between the message and the spaceship.
 */
const MessageConnector = styled.div`
	border-left: 0.1em solid transparent;
	border-right: 0.1em solid transparent;
	border-bottom: 1em solid white;
	height: 3em;
	position: absolute;
	top: -0.2em;
	left: 0.4em;
`;

/**
 * An animated spaceship.
 */
export class Rocket extends Component {
	static defaultProps = {
		cycleTargets: true,
		image: null,
		message: "",
		rotationSpeed: 0.10,
		speed: 0.01,
		targets: [
			new Target(
				50,
				50,
				() => {
					console.log("Target reached!");
				}
			)
		],
		width: "75px"
	};

	/**
	 * Creates a new Rocket.
	 * @param properties The properties.
	 */
	constructor(properties) {
		super(properties);

		this.state = {
			message: this.props.message,
			messageHideTimeout: null,
			position: {
				x: 50,
				y: 15
			},
			processedTargets: [],
			rotation: 0,
			rotationVelocity: 0,
			targets: this.props.targets,
			velocity: {
				x: 0,
				y: 0
			}
		};
	}

	/**
	 * Sets the targets, overriding any existing targets.
	 * @param {Target[]} targets The targets.
	 */
	set targets(targets) {
		this.setState({
			targets: targets,
			processedTargets: []
		});
	}

	componentDidMount() {
		// Define the delta
		const delta = 1000 / 60;

		clearInterval(this.state.animation);
		this.setState({
			animation: setInterval(() => {
				this.update(delta);
			}, delta)
		});
	}

	update(delta) {
		// Cycle targets if necessary
		if (this.props.cycleTargets && this.state.targets.length === 0) {
			this.setState({
				targets: this.state.processedTargets,
				processedTargets: []
			});
		}

		// Check if there are any targets to go to
		if (this.state.targets.length !== 0) {
			const target = this.state.targets[0];

			// Calculate the distances to the target
			const xDifference = this.state.position.x - target.x;
			const yDifference = this.state.position.y - target.y;
			const absoluteXDifference = Math.abs(xDifference);
			const absoluteYDifference = Math.abs(yDifference);
			const xyDifference = Math.sqrt(Math.pow(absoluteXDifference, 2) + Math.pow(absoluteYDifference, 2));

			/**
			 * Clip the angle to the range 0, 360.
			 * @param angle The angle.
			 */
			function clipAngle(angle) {
				let clippedAngle = angle % 360;
				if (clippedAngle < 0) {
					clippedAngle += 360;
				}

				return clippedAngle;
			}

			// Calculate the rotation
			const targetAngle = Math.atan2(xDifference, yDifference) * 180 / Math.PI + 180;
			const angleDifference = this.state.rotation - targetAngle;
			const absoluteAngleDifference = clipAngle(Math.abs(angleDifference));
			const absoluteRotationVelocity = Math.abs(this.props.rotationSpeed) * delta;
			let rotationVelocity;
			if (absoluteAngleDifference <= absoluteRotationVelocity) {
				if (targetAngle < this.state.rotation) {
					rotationVelocity = -absoluteAngleDifference;
				} else {
					rotationVelocity = absoluteAngleDifference;
				}
			} else {
				if (targetAngle < this.state.rotation) {
					if (absoluteAngleDifference < 180) {
						rotationVelocity = -absoluteRotationVelocity;
					} else {
						rotationVelocity = absoluteRotationVelocity;
					}
				} else {
					if (absoluteAngleDifference > 180) {
						rotationVelocity = -absoluteRotationVelocity;
					} else {
						rotationVelocity = absoluteRotationVelocity;
					}
				}
			}

			// Calculate the vector component ratios
			const xRatio = absoluteXDifference / xyDifference;
			const yRatio = absoluteYDifference / xyDifference;

			// Calculate the absolute velocities
			const absoluteXVelocity = xRatio * this.props.speed * delta;
			const absoluteYVelocity = yRatio * this.props.speed * delta;

			// Calculate the final vector component lengths
			let xVelocity;
			let xReached = false;
			if (absoluteXDifference <= absoluteXVelocity) {
				xVelocity = absoluteXDifference;
				xReached = true;
			} else {
				if (target.x < this.state.position.x) {
					xVelocity = -absoluteXVelocity;
				} else {
					xVelocity = absoluteXVelocity;
				}
			}
			let yVelocity;
			let yReached = false;
			if (absoluteYDifference <= absoluteYVelocity) {
				yVelocity = absoluteYDifference;
				yReached = true;
			} else {
				if (target.y < this.state.position.y) {
					yVelocity = -absoluteYVelocity;
				} else {
					yVelocity = absoluteYVelocity;
				}
			}

			// Check if we've reached the target
			if (xReached && yReached) {
				// Remove the target if so
				this.state.processedTargets.push(this.state.targets.shift());

				// Process the onReached event
				if (target.onReached !== undefined) {
					target.onReached(this);
				}
			}

			// Update the state
			this.setState({
				// Update the position based on the velocity
				position: {
					x: this.state.position.x + xVelocity,
					y: this.state.position.y + yVelocity
				},
				rotation: clipAngle(this.state.rotation + rotationVelocity),
				rotationVelocity: rotationVelocity,
				velocity: {
					x: xVelocity,
					y: yVelocity
				}
			});
		}
	}

	componentWillUnmount() {
		clearInterval(this.state.animation);
	}

	/**
	 * Shows the message in the message bubble.
	 * @param {string} message The message.
	 * @param {number} duration The duration to show the message for in milliseconds.
	 */
	showMessage(message, duration = undefined) {
		// Cancel any ongoing timeouts
		clearTimeout(this.state.messageHideTimeout);

		// Set the next timeout
		let messageHideTimeout = null;
		if (duration !== undefined) {
			messageHideTimeout = setTimeout(() => {
				this.hideMessage();
			}, duration);
		}

		// Set the message
		this.setState({
			message: message,
			messageHideTimeout: messageHideTimeout
		});
	}

	/**
	 * Hides the current message.
	 */
	hideMessage() {
		this.showMessage("");
	}

	render() {
		return (
			<Wrapper
				width={this.props.width}
				x={this.state.position.x + "vw"}
				y={this.state.position.y + "vh"}
				rotation={this.state.rotation + "deg"}
			>
				<Tip/>
				<Body/>
				<Window image={this.props.image}/>
				<LeftLeg/>
				<BackLeg/>
				<RightLeg/>
				{this.state.message !==
				"" &&
				(
					<>
						<MessageConnector/>
						<Message rotation={-this.state.rotation + "deg"}>
							{this.state.message}
						</Message>
					</>
				)}
			</Wrapper>
		);
	}
}
