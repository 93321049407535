import "@fontsource/vt323";
import {darken} from "polished";
import React, {Component} from "react";
import styled from "styled-components";

/**
 * The outer wrapper.
 */
const Wrapper = styled.div`
	position: relative;
	z-index: 75; // Positions the UFO in the middle of any planet rings
`;

/**
 * The body.
 */
const Body = styled.div`
	background: radial-gradient(at 50% 0%, ${darken(0.2, "gray")} 1em, ${darken(0.35, "gray")} 2em, rgb(30, 30, 30) 2em, rgb(30, 30, 30) 2.1em, darkgray 2.1em, darkgray 40%, ${darken(0.3, "gray")} 100%);
	border-radius: 100%;
	height: 2.5em;
	left: 0;
	position: absolute;
	top: 2em;
	width: 15em;
	z-index: 3;
`;

/**
 * The lights.
 */
const Lights = styled.div`
	animation: lightsAnimation 0.9s linear infinite;
	background: linear-gradient(to right, red, orange, green, blue, purple);
	border-radius: 100%;
	height: 2.5em;
	left: 0;
	position: absolute;
	top: 2.2em;
	width: 15em;
	z-index: 2;

	@keyframes lightsAnimation {
		0% {
			background: linear-gradient(to right, red, orange, green, blue, purple);
		}
		20% {
			background: linear-gradient(to right, orange, green, blue, purple, red);
		}
		40% {
			background: linear-gradient(to right, green, blue, purple, red, orange);
		}
		60% {
			background: linear-gradient(to right, blue, purple, red, orange, green);
		}
		80% {
			background: linear-gradient(to right, purple, red, orange, green, blue);
		}
	}
`;

/**
 * The cockpit window.
 */
const Cockpit = styled.div`
	background: radial-gradient(rgba(105, 226, 255, 0.2) 40%, ${darken(0.5, "rgba(105, 226, 255, 0.2)")} 100%);
	border-radius: 100%;
	border: 0.1em solid rgba(20, 20, 20, 0.5);
	height: 4em;
	left: 5.4em;
	position: absolute;
	top: 0;
	width: 4em;
	z-index: 1;
`;

/**
 * The beam emitted from the UFO.
 */
const Beam = styled.div`
	align-items: center;
	animation: beamAnimation 3s linear infinite;
	background: linear-gradient(rgba(0, 255, 255, 0.3) 0%, transparent 100%);
	box-shadow: 0 -1em 0.8em rgba(0, 255, 255, 0.3);
	display: flex;
	flex-direction: column;
	font-family: VT323, sans-serif;
	gap: 1em;
	height: 20em;
	justify-content: center;
	left: 3.5em;
	position: absolute;
	top: 3.5em;
	width: 8em;
	z-index: 1;

	@keyframes beamAnimation {
		0% {
			transform: scaleY(1) translateY(0);
		}
		50% {
			transform: scaleY(0.9) translateY(-0.5em);
		}
	}
`;

/**
 * A UFO.
 */
export class UFO extends Component {
	render() {
		return (
			<Wrapper>
				<Cockpit/>
				<Body/>
				<Lights/>
				<Beam>
					{this.props.children}
				</Beam>
			</Wrapper>
		);
	}
}
