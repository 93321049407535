import {Loopable} from "../Loopable";

/**
 * Handles controls.
 */
export class Controller extends Loopable {
	/**
	 * Creates a new Controller.
	 * @param {Controls} controls The Controls to use.
	 */
	constructor(controls) {
		super();

		/**
		 * The Controls to use.
		 * @type {Controls}
		 */
		this.controls = controls;
	}

	_onLoop(delta) {
		// Update the controls
		this.controls.loop(delta);
	}
}