import {darken, lighten} from "polished";
import random from "random";
import randomColor from "randomcolor";
import React, {Component} from "react";
import styled from "styled-components";

const Border = styled.div`
	background: ${props => props.border};
	border-radius: 100%;
	box-sizing: border-box;
	height: ${props => props.diameter};
	left: calc(${props => props.longitude} - (${props => props.diameter} / 2));
	padding: ${props => props.borderRadius};
	position: absolute;
	top: calc(${props => props.latitude} - (${props => props.diameter} / 2));
	width: ${props => props.diameter};
`;

const Wrapper = styled.div`
	background: radial-gradient(circle, ${props => lighten(0.1, props.background)} 0%, ${props => props.background} 66%, ${props => darken(0.3, props.background)} 100%);
	border-radius: 100%;
	height: 100%;
	width: 100%;
`;

/**
 * A continent on a Planet.
 */
export class Continent extends Component {
	static defaultProps = {
		background: null,
		border: null
	};

	static CreateRandom() {
		return (
			<Continent
				diameter={random.int(3, 76) + "%"}
				latitude={random.int(0, 100) + "%"}
				longitude={random.int(0, 100) + "%"}
				borderRadius={random.int(0, 5) + "%"}
			/>
		);
	}

	render() {
		return (
			<Border
				border={this.props.border === null ? randomColor({
					luminosity: "bright",
					format: "hex"
				}) : this.props.border}
				borderRadius={this.props.borderRadius}
				diameter={this.props.diameter}
				latitude={this.props.latitude}
				longitude={this.props.longitude}
			>
				<Wrapper
					background={this.props.background === null ? randomColor({
						luminosity: "bright",
						format: "hex"
					}) : this.props.background}
					borderRadius={this.props.borderRadius}
				>
					{this.props.children}
				</Wrapper>
			</Border>
		);
	}
}
