import * as THREE from "three";
import {Runnable} from "./Runnable";

/**
 * Something that can be looped.
 */
export class Loopable extends Runnable {
	/**
	 * Creates a new Loopable.
	 */
	constructor() {
		super();

		/**
		 * Whether the Loopable should keep running.
		 * @type {boolean}
		 */
		this.looping = false;
	}

	_onRun() {
		// Mark the Loopable as running
		this.looping = true;

		// Set up the clock
		const clock = new THREE.Clock();

		// Define the main loop
		const that = this;
		let mainLoop = function () {
			// Schedule the next update
			if (that.looping) {
				// Request the next frame and retrieve the ID
				let requestID = requestAnimationFrame(mainLoop);

				try {
					// Perform a loop
					that.loop(clock.getDelta() /
						(
							1.0 / 60.0
						));
				} catch (exception) {
					// Cancel the animation
					cancelAnimationFrame(requestID);

					// Re-throw the exception
					throw exception;
				}
			}
		};

		// Start the main loop
		mainLoop();
	}

	/**
	 * Loops the Loopable once.
	 * @param {number} delta The time difference between this and the previous frame.
	 */
	loop(delta) {
		this._onLoop(delta);
	}

	/**
	 * Called whenever the Loopable loops.
	 * @param {number} delta The time difference between this and the previous frame.
	 */
	_onLoop(delta) {
	}
}