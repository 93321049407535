/**
 * A target to fly to.
 */
export class Target {
	/**
	 * Creates a new Target.
	 * @param {number} x The x coordinate.
	 * @param {number} y The y coordinate.
	 * @param {Function} onReached The code to execute when the target is reached.
	 */
	constructor(x, y, onReached = (rocket) => {
	}) {
		/**
		 * The x coordinate.
		 * @type {number}
		 */
		this.x = x;

		/**
		 * The y coordinate.
		 * @type {number}
		 */
		this.y = y;

		/**
		 * The code to execute when the target is reached.
		 * @type {Function}
		 */
		this.onReached = onReached;
	}
}
