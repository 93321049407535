import * as THREE from "three";
import {Entity} from "./Entity";

/**
 * A light.
 */
export class Light extends Entity {
	/**
	 * Creates a new Light.
	 */
	constructor() {
		super();

		/**
		 * The helper object that can be used.
		 * @type {*}
		 * @protected
		 */
		this._helper = null;
	}

	get color() {
		return this._component.color;
	}

	set color(color) {
		this._component.color = new THREE.Color(color);
	}

	/**
	 * Gets the decay, which is the way light diminishes over distance.
	 * @return {number} The decay over distance.
	 */
	get decay() {
		return this._component.decay;
	}

	/**
	 * Sets the decay, which is the way light diminishes over distance.
	 * @param {number} decay The decay over distance.
	 */
	set decay(decay) {
		this._component.decay = decay;
	}

	/**
	 * Gets the intensity.
	 * @return {number} The intensity.
	 */
	get intensity() {
		return this._component.intensity;
	}

	/**
	 * Sets the intensity.
	 * @param {number} intensity The intensity.
	 */
	set intensity(intensity) {
		this._component.intensity = intensity;
	}

	/**
	 * Gets the maximum distance the light can travel.
	 * @return {number} The maximum distance.
	 */
	get maximumDistance() {
		return this._component.distance;
	}

	/**
	 * Sets the maximum distance the light can travel.
	 * @param {number} maximumDistance The maximum distance.
	 */
	set maximumDistance(maximumDistance) {
		this._component.distance = maximumDistance;
	}

	/**
	 * Determines if the helper light Entity is enabled.
	 * @return {boolean} Whether the helper is enabled.
	 */
	get isHelperEnabled() {
		return this._helper === null;
	}

	/**
	 * Sets whether the helper light Entity is enabled.
	 * @param {boolean} isHelperEnabled Whether the helper is enabled.
	 */
	set isHelperEnabled(isHelperEnabled) {
		if (isHelperEnabled) {
			// Create helper
			this._helper = new THREE.PointLightHelper(this._component);
			this.root._component.add(this._helper);
		} else if (this._helper !== null) {
			// Remove helper
			this.root._component.remove(this._helper);
			this._helper = null;
		}
	}

	_generateComponent() {
		return new THREE.PointLight(0xffffbb, 1, 0, 2);
	}
}
