import React, {Component} from "react";
import styled from "styled-components";

/**
 * The outer wrapper.
 */
const Wrapper = styled.section`
	border-bottom: 1px dashed #999;
	box-sizing: border-box;
	color: white;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	padding: 2em 5em;
	width: 100vw;

	@media (max-width: 500px) {
		padding: 2em 2em;
	}
`;

/**
 * The section title.
 */
const Title = styled.h2`
	position: relative;
	text-align: center;
`;

/**
 * A Spaceshow chapter.
 */
export class Section extends Component {
	render() {
		return (
			<Wrapper>
				<Title>{this.props.title}</Title>
				{this.props.children}
			</Wrapper>
		);
	}
}
