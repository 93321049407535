import {FlyControls} from "three/examples/jsm/controls/FlyControls";
import {Controls} from "./Controls";

/**
 * Used for flight.
 */
export class FlightControls extends Controls {
	_generateControls() {
		// Set up controls
		const controls = new FlyControls(this.camera._component, this.runner.renderer.element);
		controls.movementSpeed = 10;
		controls.rollSpeed = Math.PI / 24;
		controls.autoForward = false;
		controls.dragToLook = false;

		return controls;
	}

	_onLoop(delta) {
		this._controls.update(delta);
	}
}