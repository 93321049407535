import * as THREE from "three";
import {Entity} from "./Entity";

/**
 * A simulation universe.
 */
export class Universe extends Entity {
	_generateComponent() {
		// Set up a new scene
		return new THREE.Scene();
	}
}
