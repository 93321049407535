import * as THREE from "three";
import {Entity} from "./Entity";

/**
 * A camera.
 */
export class Camera extends Entity {
	/**
	 * Gets the aspect ratio.
	 * @return {number} The aspect ratio.
	 */
	get aspectRatio() {
		return this._component.aspect;
	}

	/**
	 * Sets the aspect ratio.
	 * @param {number} aspectRatio The aspect ratio.
	 */
	set aspectRatio(aspectRatio) {
		this._component.aspect = aspectRatio;
	}

	/**
	 * Gets the field of view.
	 * @return {number} The field of view.
	 */
	get fieldOfView() {
		return this._component.fov;
	}

	/**
	 * Sets the field of view.
	 * @param {number} fieldOfView The field of view.
	 */
	set fieldOfView(fieldOfView) {
		this._component.fov = fieldOfView;
	}

	_generateComponent() {
		return new THREE.PerspectiveCamera(75, 1920.0 / 1080.0, 0.1, 1e7);
	}
}
