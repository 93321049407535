import React, {Component} from "react";
import styled from "styled-components";

/**
 * The outer wrapper.
 */
const Wrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
`;

/**
 * The ring on which the items are placed.
 */
const ItemRing = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`;

const itemDiameter = "max(min(30vh, 30vw), 10em)";

/**
 * An item on the item ring.
 */
const ItemRingItem = styled.div.attrs(properties => (
	{
		style: {
			height: itemDiameter,
			left: `calc(50% + (${properties.width} / 2) * (1 - ${Math.pow(properties.tan, 2)}) / (1 + ${Math.pow(properties.tan, 2)}))`,
			top: `calc(50% + (${properties.height} / 2) * 2 * ${properties.tan} / (1 + ${Math.pow(properties.tan, 2)}))`,
			transform: `translate(-50%, -50%) scale(${properties.scale})`,
			width: itemDiameter,
			zIndex: parseInt((
				properties.scale * 100
			).toString())
		}
	}
))`
	min-height: 15em;
	min-width: 15em;
	position: absolute;
	text-align: center;
	transform-origin: center;
`;

/**
 * A Spaceshow Item Section.
 */
export class Orbit extends Component {
	/**
	 * Creates a new Orbit.
	 * @param properties The properties.
	 */
	constructor(properties) {
		super(properties);

		// Generate the items
		const items = [];
		for (let index = 0; index < this.props.children.length; ++index) {
			items.push(React.cloneElement(this.props.children[index], {
				diameter: "100%"
			}));
		}

		// Prepare the state
		this.state = {
			items: items,
			rotation: 90.0
		};
	}

	componentDidMount() {
		// Define the delta
		const delta = 1000 / 60;

		clearInterval(this.state.animation);
		this.setState({
			animation: setInterval(() => {
				this.update(delta);
			}, delta)
		});
	}

	update(delta) {
		this.setState({
			rotation: this.state.rotation + 0.01 * delta
		});
	}

	componentWillUnmount() {
		clearInterval(this.state.animation);
	}

	render() {
		return (
			<Wrapper>
				<ItemRing>
					{(
						() => {
							const width = `max(70%, calc(${this.state.items.length} * 3.5em))`;
							const height = "max(30%, 10em)";
							const space = 360 / this.state.items.length;

							// Loop the items and position them along the 3D orbit
							let angle = this.state.rotation;
							const items = [];
							for (let index = 0; index < this.state.items.length; ++index) {
								angle += space;
								const tan = Math.tan(angle / 360 * Math.PI);

								// Calculate Item size based on z position on the circle (gives 3D appearance)
								const scale = 0.25 *
									Math.sin((
										angle
									) * Math.PI / 180) +
									0.75;

								// Wrap the Item in a container that positions it along the 3D orbit
								items.push(<ItemRingItem key={index} height={height} width={width} scale={scale} tan={tan}>
									{this.state.items[index].props.header}
									{this.state.items[index]}
									{this.state.items[index].props.footer}
								</ItemRingItem>);
							}

							return items;
						}
					)()}
				</ItemRing>
			</Wrapper>
		);
	}
}
