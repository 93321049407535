import * as THREE from "three";
import {Loopable} from "../Loopable";

/**
 * Renders the graphics.
 */
export class Renderer extends Loopable {
	/**
	 * Creates a new Renderer.
	 * @param {Universe} universe The Universe to render.
	 * @param {Camera} camera The Camera to render to.
	 * @param {Element} element The Element to render to.
	 */
	constructor(universe, camera, element) {
		super();

		/**
		 * The Universe to render.
		 * @type {Universe}
		 */
		this.universe = universe;

		/**
		 * The Camera to render to.
		 * @type {Camera}
		 */
		this.camera = camera;

		/**
		 * The Element to render to.
		 * @type {Element}
		 */
		this.element = element;

		/**
		 * The underlying Three.js renderer.
		 * @type {THREE.WebGLRenderer}
		 * @private
		 */
		this._renderer = new THREE.WebGLRenderer({
			antialias: true
		});
		this._renderer.setPixelRatio(devicePixelRatio);

		// Append the renderer to the DOM
		this.element.appendChild(this._renderer.domElement);
	}

	_onLoop(delta) {
		this.render(delta);
	}

	/**
	 * Renders the specified Camera.
	 * @param {number} delta The time difference between this and the previous frame.
	 */
	render(delta) {
		// Update the size
		this._renderer.setSize(this.element.clientWidth, this.element.clientHeight);

		// Correct the Camera's aspect ratio
		this.camera.aspectRatio = this._renderer.domElement.scrollWidth / this._renderer.domElement.scrollHeight;

		// Call the event method
		this._onRender(delta);

		// Render
		this._renderer.render(this.universe._component, this.camera._component);
	}

	/**
	 * Gets called whenever the Renderer renders.
	 * @param {number} delta The time difference between this and the previous frame.
	 */
	_onRender(delta) {
	}
}