import {Vector} from "@qub1/qulibjs";
import * as THREE from "three";
import {Object3D} from "three";
import {Loopable} from "../Loopable";

/**
 * A movable object in the simulation.
 */
export class Entity extends Loopable {
	/**
	 * Creates a new Entity.
	 * @param {Vector} coordinates The coordinates.
	 */
	constructor(coordinates = new Vector()) {
		super();

		/**
		 * The Three.js component to use.
		 * @type {Object3D}
		 * @protected
		 */
		this._component = this._generateComponent();

		/**
		 * The child Entities.
		 * @type {Entity[]}
		 */
		this.children = [];

		/**
		 * The coordinates in space.
		 * @type {Vector}
		 */
		this.coordinates = coordinates;

		/**
		 * The parent Entity.
		 * @type {Entity}
		 */
		this.parent = undefined;

		/**
		 * The rotational velocity.
		 * @type {Vector}
		 */
		this.rotationVelocity = new Vector();

		/**
		 * The Universe to use.
		 * @type {Universe}
		 */
		this.universe = undefined;

		/**
		 * The velocity.
		 * @type {Vector}
		 */
		this.velocity = new Vector();
	}

	/**
	 * Gets the base color.
	 * @return {*} The base color.
	 */
	get color() {
		if (this._component.material !== undefined) {
			return this._component.material.color;
		}

		throw Error("No color property");
	}

	/**
	 * Sets the base color.
	 * @param color The base color.
	 */
	set color(color) {
		const threeColor = new THREE.Color(color);
		if (this._component.material !== undefined) {
			if (this._component.material.color !== threeColor) {
				this._component.material.color = threeColor;
			}
		} else {
			throw Error("No color property");
		}
	}

	/**
	 * Gets the coordinates.
	 * @return {Vector} The coordinates.
	 */
	get coordinates() {
		return new Vector(this._component.position.x, this._component.position.y, this._component.position.z);
	}

	/**
	 * Sets the coordinates.
	 * @param {Vector} coordinates The coordinates.
	 */
	set coordinates(coordinates) {
		this._component.position.set(coordinates.x, coordinates.y, coordinates.z);
	}

	/**
	 * Gets the depth.
	 * @return {number} The depth.
	 */
	get depth() {
		return this._component.scale.z;
	}

	/**
	 * Sets the depth.
	 * @param {number} depth The depth.
	 */
	set depth(depth) {
		this._component.scale.z = depth;
	}

	/**
	 * Gets the height.
	 * @return {number} The height.
	 */
	get height() {
		return this._component.scale.y;
	}

	/**
	 * Sets the height.
	 * @param {number} height The height.
	 */
	set height(height) {
		this._component.scale.y = height;
	}

	/**
	 * Gets the root Entity.
	 * @return {Entity} The root Entity.
	 */
	get root() {
		if (this.parent === undefined) {
			return this;
		} else {
			return this.parent.root;
		}
	}

	/**
	 * Gets the rotation.
	 * @return {Vector} The rotation.
	 */
	get rotation() {
		return new Vector(this._component.rotation.x, this._component.rotation.y, this._component.rotation.z);
	}

	/**
	 * Sets the rotation.
	 * @param {Vector} rotation The rotation.
	 */
	set rotation(rotation) {
		this._component.rotation.set(rotation.x, rotation.y, rotation.z);
	}

	/**
	 * Gets the width.
	 * @return {number} The width.
	 */
	get width() {
		return this._component.scale.x;
	}

	/**
	 * Sets the width.
	 * @param {number} width The width.
	 */
	set width(width) {
		this._component.scale.x = width;
	}

	/**
	 * Generates the component.
	 * @return {Object3D} The component.
	 */
	_generateComponent() {
		return new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), new THREE.MeshStandardMaterial({color: 0x00ff00}));
	}

	/**
	 * Adds a child Entity.
	 * @param {Entity} child The Entity to add.
	 */
	addChild(child) {
		this.children.push(child);
		child.parent = this;
		this._component.add(child._component);
	}

	_onLoop(delta) {
		this.update(delta);
	}

	/**
	 * Updates the Entity.
	 * @param {number} delta The time difference between this and the previous frame.
	 */
	update(delta) {
		// Rotate the entity
		this.rotation = new Vector(this.rotation.x + this.rotationVelocity.x * delta, this.rotation.y + this.rotationVelocity.y * delta, this.rotation.z + this.rotationVelocity.z * delta);

		// Move the entity
		this.coordinates = new Vector(this.coordinates.x + this.velocity.x * delta, this.coordinates.y + this.velocity.y * delta, this.coordinates.z + this.velocity.z * delta);

		this._onUpdate(delta);

		// Update the children
		this.children.forEach(child => child.update(delta));
	}

	/**
	 * Gets called whenever the Entity updates.
	 * @param {number} delta The time difference between this and the previous frame.
	 */
	_onUpdate(delta) {
	}
}
