import * as THREE from "three";
import {Shape} from "./Shape";

/**
 * A cube.
 */
export class Cube extends Shape {
	_generateComponent() {
		return new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), new THREE.MeshStandardMaterial({color: 0x00ff00}));
	}

	/**
	 * Sets the textures of all sides of the Cube by loading them from the specified paths.
	 * @param {string} frontTexturePath The front texture path.
	 * @param {string} backTexturePath The back texture path.
	 * @param {string} topTexturePath The top texture path.
	 * @param {string} bottomTexturePath The bottom texture path.
	 * @param {string} leftTexturePath The left texture path.
	 * @param {string} rightTexturePath The right texture path.
	 * @param {boolean} areFacingOut Whether the textures are facing outwards.
	 */
	setTextures(frontTexturePath, backTexturePath, topTexturePath, bottomTexturePath, leftTexturePath, rightTexturePath, areFacingOut = true) {
		const textureLoader = new THREE.TextureLoader();

		this._component.material = [
			frontTexturePath,
			backTexturePath,
			topTexturePath,
			bottomTexturePath,
			rightTexturePath,
			leftTexturePath
		].map(path => new THREE.MeshBasicMaterial({map: textureLoader.load(path), side: areFacingOut ? THREE.FrontSide : THREE.BackSide}));
	}
}
